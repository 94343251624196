import React, {useState, useEffect, useCallback} from 'react';
import {State, UserStatistics, Statistic} from './types';
import {Spotlight, Track} from 'spekit-datalayer';
import AnalyticsByUser from './anaylticsByUser';
import AnalyticsOverview from './analyticsOverview';
import styles from './analyze.module.css';
import {AnalyticsTeamSelectorDropdown} from 'spekit-ui';

interface Props {
  state: State;
}

const Analyze: React.FC<Props> = ({state}) => {
  const defaultSelectedTeams = ['All Teams'];

  const [loading, setloading] = useState<boolean>(true);

  const [selectedTeams, setSelectedTeams] = useState<Array<string>>(defaultSelectedTeams);

  const [statistics, setStatistics] = useState<Array<Statistic>>([]);

  const [teamNames, setTeamNames] = useState<Array<string>>(defaultSelectedTeams);

  const [APIResponse, setAPIResponse] = useState<UserStatistics>({});

  const fetchStats = useCallback(async () => {
    setloading(true);
    let response = await Spotlight.fetchAnalytics(state.id);
    setAPIResponse(response);
    setloading(false);
    Track.track('Spotlight Clicked on Analytics Dashboard', {screen_name: 'Dashboard'});
  }, [state.id]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const compileResults = useCallback((): void => {
    var compiledResults: Array<Statistic> = [];
    let alreadyPickedUsers: Array<string> = [];
    let availableTeamNames: Array<string> = [];
    for (var teamLabel in APIResponse) {
      availableTeamNames.push(teamLabel);
      if (selectedTeams.includes(teamLabel)) {
        APIResponse[teamLabel].forEach((statistic: Statistic) => {
          let {user} = statistic;
          if (!alreadyPickedUsers.includes(user.id)) {
            compiledResults.push(statistic);
            alreadyPickedUsers.push(user.id);
          }
        });
      }
    }
    setTeamNames(availableTeamNames);
    setStatistics(compiledResults);
  }, [APIResponse, selectedTeams]);

  useEffect(() => {
    compileResults();
  }, [compileResults]);

  return (
    <div>
      <div className={styles.filterByTeamContainer}>
        <div className={styles.filterByTeamText}>Filter by team</div>
        <div className={styles.filterByTeamDropdown}>
          <AnalyticsTeamSelectorDropdown
            teamOptions={teamNames}
            selectedTeams={selectedTeams}
            onTeamsSelect={(values: Array<string>) => {
              setSelectedTeams(values);
            }}
          />
        </div>
      </div>
      <AnalyticsOverview
        statistics={statistics}
        total={Object.keys(APIResponse).length}
      />
      <AnalyticsByUser
        statistics={statistics}
        loading={loading}
        host={state.host}
      ></AnalyticsByUser>
    </div>
  );
};

export default Analyze;
