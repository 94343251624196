import React from 'react';
import {RiCloseLine} from 'react-icons/ri';
import {DSButton as Button, HStack, Textarea, TTextareaProps} from 'spekit-ui';
import ResizeTextarea from 'react-textarea-autosize';

/**
 * Text area that starts as a single row and expands as needed
 * Part of some quick polish of the AI editor, similar to one use in AI Assist
 * We should consider moving this to shared components at a later date
 */
export const AIRequestInput = React.forwardRef<HTMLTextAreaElement, TTextareaProps>(
  (props, ref) => {
    return (
      <Textarea
        maxH={200}
        overflow='hidden'
        overflowY={'auto'}
        w='100%'
        resize='none'
        ref={ref}
        minRows={1}
        as={ResizeTextarea}
        {...props}
      />
    );
  }
);

interface SpekiProps {
  makeRequest: (request: string) => void;
  onClose: () => void;
}

// This component is for a temporary POC and is purpose built to work only in the editor.
export const EditorAIRequestInput = ({makeRequest, onClose}: SpekiProps) => {
  const [requestText, setRequestText] = React.useState('');

  return (
    <HStack width={'100%'} alignItems={'start'}>
      <AIRequestInput
        value={requestText}
        onChange={(e) => {
          setRequestText(e.target.value);
        }}
        maxLength={1000}
        placeholder='Request changes or modifications to your Spek'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            requestText && makeRequest(requestText);
          }
        }}
        autoFocus
        minH={'44px !important'}
        paddingTop={10}
      />
      <Button
        aria-label={'Send Request'}
        colorScheme={'primary'}
        onClick={() => requestText && makeRequest(requestText)}
        p={'20px'}
        size={'large'}
        variant='contained'
      >
        Request changes
      </Button>
      <Button
        p={'13px'}
        aria-label={'Close'}
        colorScheme={'primary'}
        onClick={onClose}
        size={'large'}
        variant='outlined'
      >
        <RiCloseLine />
      </Button>
    </HStack>
  );
};
