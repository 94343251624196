import React, {useEffect, useState} from 'react';
import {Flex, Icon, ModalBody, Spinner, Text, Graphic, SearchBar, Box} from 'spekit-ui';
import type {IDrive, IFileOrFolder, IStoreParams} from 'spekit-types';
import TreeView, {
  INode,
  ITreeViewOnNodeSelectProps,
  ITreeViewOnLoadDataProps,
  NodeId,
} from 'react-accessible-treeview';
import {RiFile2Line, RiFolder3Line} from 'react-icons/ri';
import {fileMimeTypes, mimeTypeGroups, MY_DRIVE, TIntegrationKeys} from '../constants';
import {IFlatMetadata} from 'react-accessible-treeview/dist/TreeView/utils';
import {useIntegrationApp} from '@integration-app/react';
import {CheckBoxIcon} from './CheckBoxIcon';
import {ArrowIcon} from './ArrowIcon';
import {getParams, getParentId} from '../helpers';

import 'react-folder-tree/dist/style.css';
import './tree.css';
import {logging} from 'spekit-datalayer';

interface IProps {
  store: TIntegrationKeys;
  onChange: (files: any[]) => void;
  drive?: IDrive;
}

interface IActionOutput<T> {
  records: T[];
  cursor: string;
}

export const FileSelector = (props: IProps) => {
  const {store, drive, onChange} = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState<INode[]>([
    {
      parent: null,
      id: 'root',
      name: drive?.name || '',
      children: [],
    },
  ]);

  const [currentLoadingId, setCurrentLoadingId] = useState<NodeId>();
  const [selectedIds, setSelectedIds] = useState<NodeId[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isParentLoading, setIsParentLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [oldSearchTerm, setOldSearchTerm] = useState('');

  const iApp = useIntegrationApp();

  const isBranch = (store: TIntegrationKeys, fields: IFileOrFolder['fields']) => {
    if (store === 'gdrive') {
      return fields.mimeType === fileMimeTypes.folder;
    } else if (store === 'microsoft-sharepoint') {
      return fields.itemType === 'folder';
    }
    return false;
  };

  const fetchRecords = async (extraParams: IStoreParams) => {
    const records: INode[] = [];
    let cursor = '';
    try {
      setIsLoading(true);
      do {
        const {driveId, ...params} = extraParams;

        if (cursor) params['cursor'] = cursor;
        if (driveId !== MY_DRIVE.id) params['driveId'] = driveId;

        const response = await iApp.connection(store).action('list-files').run(params);

        const output = response.output as IActionOutput<IFileOrFolder>;

        let updated = output.records.map(({fields, ...rest}) => ({
          metadata: {
            ...rest,
            ...fields,
          },
          id: rest.id,
          name: rest.name,
          parent: getParentId(fields.folderId, driveId),
          children: [],
          isBranch: isBranch(store, fields),
        }));

        if (store === 'microsoft-sharepoint') {
          const mimeTypes = [
            fileMimeTypes.pdf,
            fileMimeTypes.default.doc,
            fileMimeTypes.default.sheet,
            fileMimeTypes.default.slide,
          ];
          updated = updated.filter(
            (item) => item.isBranch || mimeTypes.includes(item.metadata.mimeType)
          );
        }

        records.push(...updated);

        cursor = output.cursor;
      } while (cursor);
      setIsLoading(false);
      return records;
    } catch (error) {
      setIsLoading(false);
      logging.capture(error);
      return [];
    }
  };

  const getInitialData = async (search?: string) => {
    if (!drive?.id) return;

    const params = getParams(store, drive, '', search);

    setIsParentLoading(true);
    const records = await fetchRecords(params);
    setIsParentLoading(false);

    setHasSearched(Boolean(search));
    setOldSearchTerm(search || '');

    const root = {
      parent: null,
      id: records?.[0]?.parent as NodeId,
      name: drive.name,
      children: records.map((el) => el.id),
    };
    setData([root, ...records]);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    getInitialData('');
  };

  useEffect(() => {
    getInitialData(searchTerm);
  }, [drive]);

  const updateTreeData = (
    list: INode[],
    id: NodeId,
    children: INode<IFlatMetadata>[]
  ) => {
    const data = list.map((node) => {
      if (node.id === id) {
        node.children = children.map((el) => {
          return el.id;
        });
      }
      return node;
    });
    return data.concat(children);
  };

  const getChildrenIds = (element: INode) => {
    const idToNodeMap = new Map(data.map((item) => [item.id, item]));

    const accumulateChildrenIds = (element: INode, result: NodeId[]) => {
      element.children.forEach((childId) => {
        const child = idToNodeMap.get(childId);
        if (!child) return;

        if (child.children.length > 0) {
          result.push(childId);
          accumulateChildrenIds(child, result);
        } else if (!child.isBranch) {
          result.push(childId);
        }
      });
    };

    const result: NodeId[] = [];
    accumulateChildrenIds(element, result);
    return result;
  };

  const handleNodeSelect = ({
    element,
    isSelected,
    autoSelectedFileIDs = [],
  }: ITreeViewOnNodeSelectProps & {autoSelectedFileIDs?: NodeId[]}) => {
    if (isSelected && !element.isBranch) {
      setSelectedIds([...selectedIds, element.id]);
    }

    if (!isSelected && !element.isBranch) {
      setSelectedIds(selectedIds.filter((i) => i !== element.id));
    }

    if (isSelected && element.isBranch) {
      let childIds = getChildrenIds(element);
      setSelectedIds((prevState) => [
        ...prevState,
        element.id,
        ...childIds,
        ...autoSelectedFileIDs,
      ]);
    }

    if (!isSelected && element.isBranch) {
      let childIds = getChildrenIds(element);
      setSelectedIds(
        selectedIds.filter((i) => i !== element.id && !childIds.includes(i))
      );
    }
  };

  const handleLoadData = async ({element, isSelected}: ITreeViewOnLoadDataProps) => {
    if (element.children.length > 0) return;

    if (!drive) return;

    let elementId = element.id;
    setCurrentLoadingId(elementId);
    if (typeof elementId !== 'string') elementId = elementId.toString();

    const params = getParams(store, drive, elementId);

    const records = await fetchRecords(params);

    setData((value) => updateTreeData(value, elementId, records));

    if (isSelected) {
      handleNodeSelect({
        element,
        isSelected,
        autoSelectedFileIDs: records
          .filter((item) => !item.isBranch)
          .map((item) => item.id),
      } as ITreeViewOnNodeSelectProps & {autoSelectedFileIDs: NodeId[]});
    }
  };

  useEffect(() => {
    const preprocess = (items: string[]) => {
      const files: IFileOrFolder[] = [];
      items.forEach((item) => {
        let node = data.find((el) => el.id === item);
        if (node && !node.isBranch) {
          const file = {
            id: node.id as string,
            name: node.name,
            uri: (node.metadata && node.metadata?.uri) || '',
            fields: {
              id: node.metadata?.id,
              name: node.metadata?.name,
              size: node.metadata?.size,
              uri: (node.metadata && node.metadata.uri) || '',
              createdTime: node.metadata?.createdTime,
              mimeType: node.metadata?.mimeType,
              downloadUri: node.metadata?.downloadUri,
              previewUri: node.metadata?.previewUri,
              folderId: node.metadata?.folderId,
              parentFolderId: node.metadata?.parentFolderId,
            },
            createdTime: node.metadata?.createdTime,
            updatedTime: node.metadata?.updatedTime,
          };
          files.push(file as IFileOrFolder);
        }
      });

      return files;
    };

    // remove duplicate keys
    const uniqueIds = [...new Set(selectedIds)].map(String);

    onChange(preprocess(uniqueIds));
  }, [selectedIds]);

  const branchNode = (isExpanded: boolean, element: INode<IFlatMetadata>) => {
    if (
      isLoading &&
      currentLoadingId === element.id &&
      isExpanded &&
      element.children.length === 0
    )
      return (
        <Spinner
          color='primary.500'
          emptyColor='neutral.200'
          size='xs'
          speed='1.6s'
          thickness='1px'
        />
      );
    if (isExpanded && element.children.length === 0) {
      return (
        <Text alignSelf='end' fontSize={12} fontStyle='italic'>
          No Data
        </Text>
      );
    }
    return null;
  };

  const getSelectionState = (
    selectedEntities: NodeId[],
    element: INode<IFlatMetadata>
  ) => {
    let isSelected: boolean | number = false;
    let isPartiallySelected: boolean | number = false;

    const {children} = element;

    // If element is not a branch, just check if it's selected.
    isSelected = !element.isBranch && selectedIds.includes(element.id);

    // If the element is a branch, check if all children are selected
    if (element.isBranch) {
      isSelected =
        children.length && children.every((child: NodeId) => selectedIds.includes(child));
    }

    // Check if at least one child is selected
    isPartiallySelected =
      children.length && children.some((child) => selectedEntities.includes(child));

    // If the element is a branch, recursively check its children
    if (element.isBranch) {
      for (const childId of children) {
        // Find the child object in data
        const childObj = data.find((d) => d.id === childId);

        if (childObj) {
          // Recursively check the child's selection status
          const childStatus = getSelectionState(selectedEntities, childObj);

          // If any child or grandchild is selected, mark the element as partially selected
          if (childStatus.isSelected || childStatus.isPartiallySelected) {
            isPartiallySelected = true;
          }
        }
      }
    }

    return {isSelected, isPartiallySelected};
  };

  const branchIcon = (element: INode<IFlatMetadata>) => {
    if (element.isBranch) {
      return <Icon fontSize={16} as={RiFolder3Line} />;
    }

    if (element.metadata && fileMimeTypes.pdf === element.metadata?.mimeType) {
      return (
        <Graphic
          variant='base'
          iconProps={{height: '16px', width: '16px'}}
          contentType='pdf'
        />
      );
    }

    if (
      element &&
      element.metadata &&
      element.metadata.mimeType &&
      typeof element.metadata.mimeType === 'string' &&
      mimeTypeGroups.slides.indexOf(element.metadata.mimeType) !== -1
    ) {
      return (
        <Graphic
          variant='base'
          iconProps={{height: '16px', width: '16px'}}
          contentType='presentation'
        />
      );
    }

    if (
      element &&
      element.metadata &&
      element.metadata.mimeType &&
      typeof element.metadata.mimeType === 'string' &&
      mimeTypeGroups.docs.indexOf(element.metadata.mimeType) !== -1
    ) {
      return (
        <Graphic
          variant='base'
          iconProps={{height: '16px', width: '16px'}}
          contentType='document'
        />
      );
    }

    if (
      element &&
      element.metadata &&
      element.metadata.mimeType &&
      typeof element.metadata.mimeType === 'string' &&
      mimeTypeGroups.sheets.indexOf(element.metadata.mimeType) !== -1
    ) {
      return (
        <Graphic
          variant='base'
          iconProps={{height: '16px', width: '16px'}}
          contentType='spreadsheet'
        />
      );
    }

    return <Icon fontSize={20} as={RiFile2Line} />;
  };

  return (
    <ModalBody my={24}>
      <Flex mb={8} align='center' pt={4}>
        <Text fontWeight={600} color='neutral.800' mr={2}>
          Select files to sync
        </Text>
        {isParentLoading && (
          <Spinner
            ml={8}
            color='primary.500'
            emptyColor='neutral.200'
            size='sm'
            speed='1.1s'
            thickness='2px'
          />
        )}
        {store === 'gdrive' && (
          <Box ml='auto' mr={8}>
            <SearchBar
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onSearch={() => getInitialData(searchTerm)}
              onClear={handleSearchClear}
              isDisabled={isParentLoading}
            />
          </Box>
        )}
      </Flex>

      {hasSearched && !isParentLoading && (
        <Text variant='caption1' mt={12} mb={16}>
          Displaying {data[0].children.length} search results for “{oldSearchTerm}”
        </Text>
      )}

      {data[0].children.length > 0 && (
        <Box sx={isParentLoading ? {opacity: 0.5, pointerEvents: 'none'} : {}}>
          <TreeView
            className='file-selector'
            data={data}
            onLoadData={handleLoadData}
            onNodeSelect={handleNodeSelect}
            selectedIds={selectedIds}
            multiSelect
            togglableSelect
            nodeRenderer={({
              element,
              isBranch,
              isExpanded,
              getNodeProps,
              level,
              handleSelect,
              handleExpand,
            }) => {
              const {isSelected, isPartiallySelected} = getSelectionState(
                selectedIds,
                element
              );
              return (
                <div
                  {...getNodeProps({onClick: handleExpand})}
                  style={{marginLeft: 46 * (level - 1), maxWidth: 'fit-content'}}
                >
                  <Flex
                    align={element.name.length > 110 ? 'start' : 'center'}
                    gap={8}
                    maxWidth={'fit-content'}
                    data-testid={`${element.name}-file-row`}
                  >
                    {isBranch && <ArrowIcon isOpen={isExpanded} />}

                    <CheckBoxIcon
                      variant={isSelected ? 'all' : isPartiallySelected ? 'some' : 'none'}
                      data-checked={isSelected || isPartiallySelected}
                      onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        !isExpanded && handleExpand(e);
                        handleSelect(e);
                        e.stopPropagation();
                      }}
                    />

                    {branchIcon(element)}

                    <Text
                      variant='body2'
                      fontWeight={400}
                      mt={element.name.length > 110 ? '-2px' : 0}
                    >
                      {element.name}
                    </Text>

                    {branchNode(isExpanded, element)}
                  </Flex>
                </div>
              );
            }}
          />
        </Box>
      )}
    </ModalBody>
  );
};
