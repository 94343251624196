import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';

import {RiCloseLine, RiArrowLeftLine} from 'react-icons/ri';
import {
  ActionWithConfirmation,
  DSButton as Button,
  DSModal as Modal,
  Flex,
  Heading,
  Icon,
  notifications,
  ModalFooter,
  Divider,
  Text,
} from 'spekit-ui';
import {ContentStores, FileSelector, IMetadata, MetadataForm} from './components';
import {IFileOrFolder, IDrive} from 'spekit-types';
import {contentIntegrations, logging} from 'spekit-datalayer';
import {TIntegrationKeys} from './constants';
import {useConnections} from './hooks/useConnections';
import {useAnalytics} from '../providers';
import {useIntegrationApp} from '@integration-app/react';
import {connectionEventTracker} from './helpers';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  containerRef?: RefObject<HTMLElement>;
  hasContentIntegrationSharepointFlag?: boolean;
  host?: string;
}

export function ContentImportModal(props: IProps) {
  const {notify} = notifications;
  const {track} = useAnalytics();

  const {isOpen, onClose, containerRef, hasContentIntegrationSharepointFlag, host} =
    props;
  const [selectedDrive, setSelectedDrive] = useState<IDrive>();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedStore, setSelectedStore] = useState<TIntegrationKeys>('gdrive');
  const [step, setStep] = useState(1);

  const [_isOpen, _setIsOpen] = useState(isOpen);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<IFileOrFolder[]>([]);
  const [metadata, setMetadata] = useState<IMetadata>({});
  const [hasTopicsError, setHasTopicsError] = useState(false);

  const {connections, stores} = useConnections(hasContentIntegrationSharepointFlag);

  const integrationApp = useIntegrationApp();
  const queryClient = useQueryClient();
  const [storeName, setStoreName] = useState('');

  // We only want to set the active tab once. This is to prevent the active tab from being set
  // before the connections are loaded.
  const hasSetActiveTab = useRef(false);

  useEffect(() => {
    setStoreName(
      stores.find((store) => store.connectionKey === selectedStore)?.label || ''
    );
  }, [selectedStore]);

  useEffect(() => {
    // We only want to set the active tab once.
    const abortController = new AbortController();
    const {signal} = abortController;

    const setActiveTab = async () => {
      try {
        if (signal.aborted) return;

        if (!hasSetActiveTab.current) {
          // Return early if any connections are still loading
          const hasLoadingConnections = Object.values(connections).some(
            (connection) => connection === undefined
          );
          if (hasLoadingConnections) {
            return;
          }

          const connectionEntries = Object.entries(connections) as [
            TIntegrationKeys,
            any
          ][];
          const firstConnectedEntry = connectionEntries.find(
            ([_, connection]) => connection && !connection.disconnected
          );

          if (firstConnectedEntry) {
            const [store, _] = firstConnectedEntry;
            const connectedIndex = connectionEntries.findIndex(([key]) => key === store);

            hasSetActiveTab.current = true;

            // Only update if not the first tab
            if (connectedIndex > 0) {
              setActiveTabIndex(connectedIndex);
              setSelectedStore(store);
            }
          }
        }
      } catch (error) {
        if (!signal.aborted) {
          logging.capture(error, {
            message: 'Error setting active tab in ContentImportModal',
          });
        }
      }
    };

    setActiveTab();

    return () => {
      abortController.abort();
    };
  }, [connections, setActiveTabIndex, setSelectedStore]);

  const handleBack = () => {
    setStep(step - 1);
    setHasTopicsError(false);
  };

  const handleNext = () => {
    if (step === 1) {
      track('File Import Drive Selected', {
        drive_name: selectedDrive?.name,
        integration: selectedStore,
      });
    }

    if (step === 2) {
      track('File Import Files Selected', {
        number_of_files_selected: selectedFiles.length,
        integration: selectedStore,
      });
    }

    setStep(step + 1);
    setHasTopicsError(false);
  };

  const handleSelectDrive = (store: TIntegrationKeys, drive: IDrive) => {
    setSelectedStore(store);
    setSelectedDrive(drive);
  };

  const handleTreeChange = useCallback((files: IFileOrFolder[]) => {
    setSelectedFiles(files);
  }, []);

  const handleMetadataChange = (metadata: IMetadata) => {
    setMetadata(metadata);
  };

  const handleDisconnect = async () => {
    const trackConnectionEvent = connectionEventTracker(
      selectedStore,
      'ContentImportModal',
      track
    );
    setIsDisconnecting(true);
    trackConnectionEvent('disconnect-init', '', 'disconnect init by user');
    try {
      await integrationApp.integration(selectedStore).disconnect();
      await queryClient.invalidateQueries(['store', selectedStore]);
      trackConnectionEvent('disconnected', '', 'disconnect success');
    } catch (error) {
      trackConnectionEvent('disconnect-error', error.toString(), 'disconnect error');
    } finally {
      setIsDisconnecting(false);
    }
  };

  const handleConnect = async (connectionKey: TIntegrationKeys) => {
    const trackConnectionEvent = connectionEventTracker(
      connectionKey,
      'ContentImportModal',
      track
    );
    try {
      _setIsOpen(false);
      trackConnectionEvent('init', '', '');
      const newConnection = await integrationApp
        .integration(connectionKey)
        .openNewConnection({showPoweredBy: false});
      await queryClient.invalidateQueries(['store', connectionKey]);
      if (newConnection) {
        trackConnectionEvent('success', '', '');
      } else {
        trackConnectionEvent('fail', 'unknown error', '');
      }
    } catch (error) {
      console.error(error);
      trackConnectionEvent('fail', error.toString(), 'exception occurred');
    } finally {
      _setIsOpen(true);
    }
  };

  const handleImport = async () => {
    const selectedConnection = connections[selectedStore];

    if (metadata?.selectedTopics?.length === 0) {
      setHasTopicsError(true);
      return;
    }

    if (
      selectedConnection &&
      metadata.selectedTopics &&
      metadata.selectedTopics.length > 0
    ) {
      const payload = {
        file_store_ids: selectedFiles.map((file) => file.id),
        connection_key: selectedStore,
        tag_ids: metadata.selectedTopics,
        shareable: metadata.privacyIsChecked,
        custom_columns: metadata.customFields,
      };

      try {
        setIsSubmitting(true);
        const response = await contentIntegrations.importAssets(payload);
        if (response) {
          notify({
            text: 'Your files are being imported in the background. You will be notified once the import is complete.',
          });

          onClose();
        }
        setIsSubmitting(false);
        track('File Import', {
          number_of_files_imported: selectedFiles.length,
          integration: selectedStore,
        });
      } catch (error) {
        setIsSubmitting(false);
        logging.capture(error);
      }
    }
  };

  const handleFileRemove = (fileId: string) => {
    setSelectedFiles(selectedFiles.filter((file) => file.id !== fileId));
  };

  const renderModalBody = () => {
    if (step === 1) {
      return (
        <ContentStores
          onDriveSelect={handleSelectDrive}
          onStoreClick={setSelectedStore}
          selectedDrive={selectedDrive}
          handleConnect={handleConnect}
          hasContentIntegrationSharepointFlag={hasContentIntegrationSharepointFlag}
          setActiveTab={setActiveTabIndex}
          activeTab={activeTabIndex}
        />
      );
    }

    if (step === 2) {
      return (
        <FileSelector
          store={selectedStore}
          drive={selectedDrive}
          onChange={handleTreeChange}
        />
      );
    }

    // step = 3 is final step
    return (
      <MetadataForm
        files={selectedFiles}
        onChange={handleMetadataChange}
        onRemove={handleFileRemove}
        selectedStore={selectedStore}
        host={host}
        hasTopicsError={hasTopicsError}
        setHasTopicsError={setHasTopicsError}
      />
    );
  };

  let isNextBtnDisabled = false;
  if (step === 1 && !selectedDrive) {
    isNextBtnDisabled = true;
  } else if (step === 2) {
    isNextBtnDisabled = selectedFiles?.length === 0;
  } else if (step === 3) {
    if (
      isSubmitting ||
      !metadata.selectedTopics ||
      metadata.selectedTopics.length === 0
    ) {
      isNextBtnDisabled = true;
    } else {
      isNextBtnDisabled = false;
    }
  }

  return (
    <Modal
      portalProps={{containerRef}}
      isOpen={_isOpen}
      blockScrollOnMount={false}
      trapFocus={false}
      closeOnEsc={false}
      onClose={onClose}
      isCentered
      size='lg'
      shouldWrapOverSidebar
      scrollBehavior='inside'
    >
      <Flex alignItems='center' justifyContent='space-between'>
        <Heading as='h4' fontWeight='semibold' data-testid='modal-heading'>
          Sync files to Spekit
        </Heading>
        <Flex align='center'>
          {step === 3 && (
            <>
              <Button
                variant='contained'
                isLoading={isSubmitting}
                colorScheme='primary'
                size='medium'
                onClick={handleImport}
                data-testid='sync-file-btn'
              >
                Sync files
              </Button>
              <Divider orientation='vertical' h='30px' ml={20} mr={10} />
            </>
          )}

          <ActionWithConfirmation
            icon={RiCloseLine}
            confirmationHeader='Are you sure you want to close?'
            confirmationMessage='Changes made will be lost once you close this window.'
            confirmActionText='Yes, close'
            confirmAction={onClose}
            actionTooltip='Close'
            skipConfirmation={step === 1 && !selectedDrive}
          />
        </Flex>
      </Flex>

      {renderModalBody()}

      <ModalFooter p={0} m={0} justifyContent={'space-between'}>
        {step === 1 &&
          connections[selectedStore] &&
          !connections[selectedStore].disconnected && (
            <ActionWithConfirmation
              confirmationHeader={`Disconnect ${storeName}?`}
              confirmationMessage={`Are you sure? You will no longer be able to sync ${storeName} files into Spekit.`}
              confirmActionText='Yes, disconnect'
              confirmAction={handleDisconnect}
              placement='top-start'
              customButton={
                <Button
                  data-testid='confirmation-popover-btn'
                  variant='danger'
                  size='medium'
                  isLoading={isDisconnecting}
                >
                  Disconnect
                </Button>
              }
            />
          )}

        {step !== 1 && (
          <Button
            leftIcon={<Icon as={RiArrowLeftLine} />}
            variant='ghost'
            onClick={handleBack}
            colorScheme='white'
            size='medium'
            data-testid='treeview-back-button'
          >
            Back
          </Button>
        )}
        {step !== 3 &&
          connections[selectedStore] &&
          !connections[selectedStore].disconnected && (
            <Flex justifyContent='space-between' alignItems='center' gap='16'>
              {step === 2 && (
                <Text
                  data-testid='files-count-label'
                  variant='caption1'
                  fontWeight={400}
                  lineHeight={4}
                >
                  {selectedFiles.length} file{selectedFiles.length === 1 ? '' : 's'}{' '}
                  selected
                </Text>
              )}
              <Button
                variant='contained'
                disabled={isNextBtnDisabled}
                isLoading={isSubmitting}
                colorScheme='primary'
                size='medium'
                onClick={handleNext}
                data-testid='next-btn'
              >
                Next
              </Button>
            </Flex>
          )}
      </ModalFooter>
    </Modal>
  );
}
