/**
 * Converts a CSS style string into an object with camelCase property names.
 *
 * - This function parses a CSS style string (e.g., `'color: red; font-size: 12px;'`)
 *   and converts it into an object where each CSS property is transformed to camelCase.
 * - Invalid CSS properties and values are ignored, and warnings are logged for each.
 * - The function also verifies if the input is a string, returning an empty object if not.
 *
 * @param {string} [styleString] - The CSS style string to convert.
 * @returns {Record<string, string>} - An object containing camelCase CSS properties and values.
 */
export const stringStyleConverter = (styleString?: string): Record<string, string> => {
  const KEBAB_CASE_REGEX = /-([a-z])/g;

  if (!styleString) {
    return {};
  }

  if (typeof styleString !== 'string') {
    console.warn(
      'stringStyleConverter expected a string input but received:',
      typeof styleString
    );
    return {};
  }

  return styleString
    .split(';')
    .filter((rule) => rule.trim()) // Filter out empty rules
    .reduce((acc: Record<string, string>, rule: string) => {
      try {
        const colonIndex = rule.indexOf(':');
        if (colonIndex === -1) {
          console.warn(`Invalid CSS rule (missing colon): ${rule}`);
          return acc;
        }
        const property = rule.slice(0, colonIndex).trim();
        const value = rule.slice(colonIndex + 1).trim();
        if (property && value) {
          // Validate property name contains only valid characters
          if (!/^[a-zA-Z-]+$/.test(property)) {
            console.warn(`Invalid CSS property name: ${property}`);
            return acc;
          }
          // Convert kebab-case to camelCase
          const camelCaseProperty = property.replace(KEBAB_CASE_REGEX, (g: string) =>
            g[1].toUpperCase()
          );
          acc[camelCaseProperty] = value;
        }
      } catch (error) {
        console.warn(`Failed to parse CSS rule: ${rule}`, {
          error: error instanceof Error ? error.message : 'Unknown error',
          rule,
        });
      }
      return acc;
    }, {});
};
