import React from 'react';
import {useFormContext} from 'react-hook-form';
import {RiInformationLine, RiDeleteBin6Line} from 'react-icons/ri';
import {useSrcFromFile} from 'spekit-shared-components';
import {ICustomBrandingFormState, TCustomBrandingFromStateImageKeys} from 'spekit-types';
import {
  Flex,
  Icon,
  Text,
  DSTooltip as Tooltip,
  DSButton as Button,
  Image,
  useToast,
} from 'spekit-ui';
import {setValueOptions, LOGO_THEME_CONFIG, LOGO_PREVIEW} from './constants';
import {UploadButton} from './UploadButton';

interface ILogoUploader {
  label: 'Logotype' | 'Logomark';
  tooltipLabel: string;
  deleteTooltipLabel: string;
  testIdPrefix: string;
  fieldName: TCustomBrandingFromStateImageKeys;
  variant: 'light' | 'dark';
  defaultLogo: string;
}

const getImageUrl = (url: string) => `${window.location.origin}${url}`;

export const LogoUploader = ({
  label,
  tooltipLabel,
  deleteTooltipLabel,
  testIdPrefix,
  fieldName,
  variant,
  defaultLogo,
}: ILogoUploader) => {
  const {
    watch,
    formState: {errors},
    setValue,
  } = useFormContext<ICustomBrandingFormState>();
  const logoImage = watch(fieldName);
  const error = errors[fieldName]?.message;

  const {previewContainer, previewImageProps, previewDefaultSize} = LOGO_PREVIEW[label];
  const config = LOGO_THEME_CONFIG[variant];
  const borderColor = error ? 'error.600' : config.borderColor;

  const logoSrc = useSrcFromFile(logoImage instanceof File ? logoImage : null);
  const defaultLogoSrc =
    typeof logoImage === 'string' ? getImageUrl(logoImage) : defaultLogo;
  const previewLogoSrc = logoSrc || defaultLogoSrc;

  const setFile = (file: File | null) => setValue(fieldName, file, setValueOptions);

  const toast = useToast();
  return (
    <Flex direction='column' alignItems='flex-start'>
      {/* Header */}
      <Flex gap={4} mb={16}>
        <Text variant='body2'>{label}</Text>
        <Tooltip label={tooltipLabel}>
          <Flex alignItems='center' data-testid={`${label}-tooltip`}>
            <Icon as={RiInformationLine} h={4} w={4} />
          </Flex>
        </Tooltip>
      </Flex>
      {/* Previewer and Restore to default */}
      <Flex direction='column' mb={12} gap={6}>
        <Flex gap={8} alignItems='center'>
          <Flex
            alignItems='center'
            justifyContent='center'
            borderRadius={8}
            border='1px solid'
            borderColor={borderColor}
            background={config.background}
            {...previewContainer}
          >
            <Image
              {...previewImageProps}
              {...(previewLogoSrc === defaultLogo ? previewDefaultSize : {})}
              data-testid={`${testIdPrefix}-preview-image`}
              src={previewLogoSrc}
            />
          </Flex>
          <UploadButton
            setFile={setFile}
            label={label}
            testId={`${testIdPrefix}-upload-btn`}
            fieldName={fieldName}
          />
          <Button
            variant='danger'
            size='medium'
            data-testid={`${testIdPrefix}-delete-btn`}
            leftIcon={<Icon as={RiDeleteBin6Line} h={4} w={4} />}
            iconSpacing={0}
            onClick={() => {
              setFile(null);
              toast({
                variant: 'success',
                description: `${label} removed and reverted to default selection`,
              });
            }}
            p={8}
            tooltipLabel={deleteTooltipLabel}
          />
        </Flex>
        {error && (
          <Text
            variant='caption1'
            color='error.600'
            maxW='172px'
            data-testid={`${testIdPrefix}-error`}
          >
            {error}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
