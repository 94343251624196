import React from 'react';
import {SimpleAnchor} from './SimpleAnchor';
import {RiOrganizationChart} from 'react-icons/ri';
import {Box, Icon, ICONS} from 'spekit-ui';
import {TAssetMimeType, TContentType} from 'spekit-types';
import {getDocumentTypeFromContentType} from 'spekit-datalayer';

/**
 * Mention component
 * This component is used to display mentions in read-only mode.
 * It allows us to add an icon, tooltips, and other customizations to the CKEditor mention output.
 * It is only used for Speks right now, but will be updated to support all record types.
 */
interface MentionProps {
  link: string;
  type: TContentType;
  label: string;
  openInSidebar?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string
  ) => void;
  tabIndex?: number;
  contentType: TAssetMimeType;
}

function getMentionOptions(type: TContentType, contentType: TAssetMimeType) {
  if (type === 'asset' && contentType) {
    const documentType = getDocumentTypeFromContentType(contentType);
    return {
      icon: ICONS[documentType],
      colorBase: documentType,
    };
  }
  return {
    icon: RiOrganizationChart,
    colorBase: 'default.primary',
  };
}

export const Mention = ({
  label,
  link,
  type,
  openInSidebar,
  contentType,
  tabIndex,
}: MentionProps) => {
  const {colorBase, icon} = getMentionOptions(type, contentType);
  return (
    <Box
      as='span'
      backgroundColor={`${colorBase}.25`}
      color={`${colorBase}.500`}
      pr={4}
      pl={4}
      borderRadius={4}
      cursor='pointer'
      display='inline-flex'
      alignItems='center'
      gap={4}
      maxW='95%'
      verticalAlign='sub'
      sx={{
        '& a': {
          color: `var(--spekit-colors-${colorBase.replace('.', '-')}-500) !important`,
          textDecoration: 'none !important',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        },
      }}
    >
      <Icon as={icon} boxSize={4} color={`${colorBase}.500`} />
      <SimpleAnchor
        openInSidebar={openInSidebar}
        href={link}
        target='_self'
        tabIndex={tabIndex}
      >
        {label}
      </SimpleAnchor>
    </Box>
  );
};
