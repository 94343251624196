/**
 * Map of all supported file types and their corresponding mime types
 *
 * Modify the values to be arrays so that multiple mime types can be supported
 * per file type supported
 * e.g. PPTX can be `application/vnd.openxmlformats-officedocument.presentationml.presentation` or `application/vnd.ms-powerpoint`
 * or PDF can be `application/pdf` or `application/x-pdf`
 */

export const fileMimeTypes = {
  pdf: 'application/pdf',
  folder: 'application/vnd.google-apps.folder',
  google: {
    slide: 'application/vnd.google-apps.presentation',
    doc: 'application/vnd.google-apps.document',
    sheet: 'application/vnd.google-apps.spreadsheet',
  },
  default: {
    slide: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    doc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    sheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
};

export const mimeTypeGroups = {
  slides: [fileMimeTypes.google.slide, fileMimeTypes.default.slide],
  docs: [fileMimeTypes.google.doc, fileMimeTypes.default.doc],
  sheets: [fileMimeTypes.google.sheet, fileMimeTypes.default.sheet],
};

export const mimeTypeToGraphicType = (mimeType: string) => {
  switch (mimeType) {
    case fileMimeTypes.pdf:
      return 'pdf';
    case fileMimeTypes.google.slide:
    case fileMimeTypes.default.slide:
      return 'presentation';
    case fileMimeTypes.google.doc:
    case fileMimeTypes.default.doc:
      return 'document';
    case fileMimeTypes.google.sheet:
    case fileMimeTypes.default.sheet:
      return 'spreadsheet';
    default:
      return 'document';
  }
};

export const MY_DRIVE = {
  id: 'myDrive',
  name: 'My Drive',
};

/**
 * Integration app External app string
 */
export const INTEGRATION_KEY_GOOGLE = 'gdrive';
export const INTEGRATION_KEY_SHAREPOINT = 'microsoft-sharepoint';

export const INTEGRATION_CONNECTION_KEYS = [
  INTEGRATION_KEY_GOOGLE,
  INTEGRATION_KEY_SHAREPOINT,
] as const;

export type TIntegrationKeys = typeof INTEGRATION_CONNECTION_KEYS[number];
export interface IContentStore {
  connectionKey: TIntegrationKeys;
  label: string;
}

export const getFileTagFromMimeType = (
  mimeType: string,
  store: TIntegrationKeys | undefined = undefined
) => {
  const fileTagMap = {
    [fileMimeTypes.pdf]: 'PDF',
    [fileMimeTypes.google.doc]: 'GDOC',
    [fileMimeTypes.google.slide]: 'GSLIDE',
    [fileMimeTypes.google.sheet]: 'GSHEET',

    [fileMimeTypes.default.doc]: (store: TIntegrationKeys | undefined) =>
      store === INTEGRATION_KEY_GOOGLE ? 'GDOC' : 'DOCX',
    [fileMimeTypes.default.slide]: (store: TIntegrationKeys | undefined) =>
      store === INTEGRATION_KEY_GOOGLE ? 'GSLIDE' : 'PPTX',
    [fileMimeTypes.default.sheet]: (store: TIntegrationKeys | undefined) =>
      store === INTEGRATION_KEY_GOOGLE ? 'GSHEET' : 'XLSX',
  };

  const fileTag = fileTagMap[mimeType];
  if (typeof fileTag === 'function') {
    return fileTag(store);
  }
  return fileTag || 'DOCX';
};
